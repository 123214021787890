import TurtleMetadata from '../Nftdata/TurtleMetadata'
import Background from '../assets/images/grassfield.png'

import elementicon1 from '../assets/images/elementsymbols/Fire.png'
import elementicon2 from '../assets/images/elementsymbols/Water.png'
import elementicon3 from '../assets/images/elementsymbols/Poison.png'
import elementicon4 from '../assets/images/elementsymbols/Electric.png'
import elementicon5 from '../assets/images/elementsymbols/Frost.png'
import elementicon6 from '../assets/images/elementsymbols/Rock.png'
import elementicon7 from '../assets/images/elementsymbols/Nature.png'
import PulseLoader from 'react-spinners/PulseLoader'
import shelllogo from '../assets/images/shellcoin.png'

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../redux/data/dataActions';
import { connect } from '../redux/blockchain/blockchainActions';
import { db } from '../firebase';
import { onValue, ref } from 'firebase/database';
import store from '../redux/store'

function TurtleRenderer() {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    let pathname = window.location.pathname
    let pageid = pathname.substring(1)

    const elementcolors = { color: '' }
    let elementcolor = '';
    let elementicon = '';
    if (TurtleMetadata[pageid-1].attributes[1].value === 'Fire') { elementcolor = 'red'; elementicon = elementicon1; elementcolors.color = '#DF6B6A' }
    if (TurtleMetadata[pageid-1].attributes[1].value === 'Water') { elementcolor = 'blue'; elementicon = elementicon2; elementcolors.color = '#7F91E6' }
    if (TurtleMetadata[pageid-1].attributes[1].value === 'Poison') { elementcolor = 'purple'; elementicon = elementicon3; elementcolors.color = '#BB8FE6' }
    if (TurtleMetadata[pageid-1].attributes[1].value === 'Electric') { elementcolor = 'yellow'; elementicon = elementicon4; elementcolors.color = '#F4E07A' }
    if (TurtleMetadata[pageid-1].attributes[1].value === 'Frost') { elementcolor = 'cyan'; elementicon = elementicon5; elementcolors.color = '#B3E0D8' }
    if (TurtleMetadata[pageid-1].attributes[1].value === 'Rock') { elementcolor = 'orange'; elementicon = elementicon6; elementcolors.color = '#F4AB67' }
    if (TurtleMetadata[pageid-1].attributes[1].value === 'Nature') { elementcolor = 'green'; elementicon = elementicon7; elementcolors.color = '#C2EA94' }

    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        STAKINGCONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: ""
    });

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    function changeTurtleName() {
        let newName = document.getElementById('textInputTurtle').value
        let pathname = window.location.pathname
        let pageid = pathname.substring(1)

        console.log(pageid)
        console.log(String(newName))

        blockchain.smartContract.methods.changeTurtleName(String(pageid), String(newName))
            .send({
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account
            }).then((receipt) => {
                let turtlename = document.getElementById('turtlenametext')
                turtlename.innerText = newName
            }
        )
    }

    const [turtleData, setTurtledata] = useState(false);
    useEffect(() => {
        let pathname = window.location.pathname
        let pageid = pathname.substring(1)
        for (let index = 0; index < data["turtleOwner"].length; index++) {
            if (data["turtleOwner"][index] === pageid) {
                setTurtledata(true)
            }   
        }
        getturtlename()
        if (parseInt(data["approveAllowance"]) >! 0 && parseInt(data["approveContractAllowance"]) >! 0) {
            setshowapprove(true)
        }
    }, [data]);

    const [turtlename, setTurtlename] = useState(TurtleMetadata[pageid-1]["name"]);

    async function getturtlename() {
        let turtleName = await store
        .getState()
        .blockchain.smartContract.methods.turtles(pageid)
        .call();
        setTurtlename(turtleName[1])
    }

    function approveShellsToTubbyTurtles() {
        let approveAmount = '500000000000000000000';
        let approveAmountStaking = '5000000000000000000000';
        let cost = 0;
        let totalCostWei = String(cost);

        blockchain.stakingSmartContract.methods.approve(CONFIG.CONTRACT_ADDRESS, approveAmount)
            .send({
                to: CONFIG.STAKINGCONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
        blockchain.stakingSmartContract.methods.approve(CONFIG.STAKINGCONTRACT_ADDRESS, approveAmountStaking)
            .send({
                to: CONFIG.STAKINGCONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
    }

    const [showapprove, setshowapprove] = useState(false);

    return ( 
    <div style={{backgroundImage: `url(${Background})`}} className='turtlerendererbg min-h-[100vh] bg-[#83B85B] bg-no-repeat 2xl:bg-cover'>
        <div className='overflow-hidden h-auto w-auto flex flex-col md2:flex-row xl:px-16 2xl:px-28'>
            <div className='mt-[110px] md:mt-[130px] flex mx-auto w-[75%] sm:w-[65%] md2:p-4 md2:mt-[140px] lg:max-h-[calc(100vh-150px)] lg:max-w-[calc(100vh-150px)]'>
                <img className='rounded-lg shadow-xl aspect-square' style={{border: '20px solid'+elementcolors.color}} src={TurtleMetadata[pageid-1].image} alt=""/>
            </div>
            <div className='w-[90%] mx-auto md2:w-[55%] xl:w-[50%] 2xl:w-[40%] md2:px-6 md2:mt-[140px]'>
                <div className='rounded-lg shadow-xl bg-[#f0a657] border-[calc(5px+0.5vw)] border-[#c48747] mb-4 mt-4'>
                {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                    <div className='h-full w-full flex justify-center items-center'>
                        <div className='mb-4 flex flex-col justify-center items-center'>
                            <button className='mt-3 mb-1 flex pl-1 pr-2 mx-auto justify-center rounded-2xl items-center
                            bg-green-500 border-b-[5px] border-[rgb(24,136,65)] hover:brightness-[1.05] active:border-b-[3px] active:translate-y-[6px] active:mt-[2px]' onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(connect());
                                    getData();
                                }}>

                                <svg width="50px" height="50px" className="metamaskicon" strokeLinejoin="round" viewBox="0 0 318.6 318.6" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m274.1 35.5-99.5 73.9 18.4-43.6z" fill="#e2761b" stroke="#e2761b"></path><g fill="#e4761b" stroke="#e4761b"><path d="m44.4 35.5 98.7 74.6-17.5-44.3zm193.9 171.3-26.5 40.6 56.7 15.6 16.3-55.3z"></path><path d="m33.9 207.7 16.2 55.3 56.7-15.6-26.5-40.6zm69.7-69.5-15.8 23.9 56.3 2.5-2-60.5z"></path><path d="m214.9 138.2-39-34.8-1.3 61.2 56.2-2.5zm-108.1 109.2 33.8-16.5-29.2-22.8zm71.1-16.5 33.9 16.5-4.7-39.3z"></path></g><path d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z" fill="#d7c1b3" stroke="#d7c1b3"></path><path d="m138.8 193.5-28.2-8.3 19.9-9.1zm40.9 0 8.3-17.4 20 9.1z" fill="#233447" stroke="#233447"></path><path d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z" fill="#cd6116" stroke="#cd6116"></path><path d="m87.8 162.1 23.6 46-0.8-22.9zm120.3 23.1-1 22.9 23.7-46zm-64-20.6-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0-2.7 18 1.2 45 6.7-34.1z" fill="#e4751f" stroke="#e4751f"></path><path d="m179.8 193.5-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3 0.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z" fill="#f6851b" stroke="#f6851b"></path><path d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z" fill="#c0ad9e" stroke="#c0ad9e"></path><path d="m177.9 230.9-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z" fill="#161616" stroke="#161616"></path><path d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z" fill="#763d16" stroke="#763d16"></path><path d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z" fill="#f6851b" stroke="#f6851b"></path>
                                </svg>
                                Connect
                            </button>

                            {blockchain.errorMsg !== "" ? (
                                <>
                                    <h2 className='text-center mb-2 errormsg'>{blockchain.errorMsg}</h2>
                                </>
                            ) : null}
                        </div>
                        </div>
                    ) : (
                        <div className='flex flex-col justify-center items-center w-full'>
                            <div className='text-lg px-[20px] py-[30px]'>
                                <div className='flex flex-row mb-1'>
                                    <div className='text-xl flex flex-col'>
                                        <p className='pr-2 font-bold'>Id:</p>
                                        <p className='pr-2 font-bold'>Name:</p>
                                    </div>
                                    <div className='text-xl flex flex-col'>
                                        <p>#{TurtleMetadata[pageid-1].tokenId}</p>
                                        <p id='turtlenametext'>{turtlename}</p>
                                    </div>
                                </div>
                                {turtleData === true && data["loading"] === false && showapprove === false ? (
                                <div className='my-2 pb-2 flex flex-col border-t-4 border-b-4 rounded-lg border-[#ffffff]'>
                                    <h2 className='text-lg text-center'>To perform actions like staking and name changing, you first need to have Shell tokens and you need to approve the contract so it's able to spend your Shells.</h2>
                                    <h2 className='md:w-[75%] md:mx-auto text-base text-center'>(The button below suggests 2 transaction; 1 for allowing the NFT contract to use Shell tokens, and 1 for allowing the staking contract to use your Shell tokens.)</h2>
                                    <div className='mx-auto h-[64px]'>
                                        <button className='bg-[#22C55E] border-[#188841] border-b-[5px] active:border-b-[2px] active:translate-y-[3px] text-2xl rounded-lg py-[5px] px-[10px] flex flex-row items-center' onClick={approveShellsToTubbyTurtles}>Approve Shells<img className='ml-[6px] my-auto w-11 h-11' src={shelllogo} /></button>
                                    </div>
                                </div>
                                ) : (
                                    <div></div>
                                )}
                                {data["loading"] === true ? (
                                    <div>
                                        <PulseLoader />
                                    </div>
                                ) : (
                                    null
                                )}
                                {turtleData === true && data["loading"] === false ? (
                                    <div>
                                        <input className='sm:w-[275px] mb-2 px-[10px] py-[2px]' id='textInputTurtle' type="text" name='name' placeholder={'New turtle name (max. 19 char.)'} minLength='1' maxLength='18' required autoFocus autoComplete='off'/>
                                        <button className='bg-green-500 rounded-md ml-1 p-1' id='submitNameButton' onClick={changeTurtleName}>Submit</button>
                                    </div>
                                    ) :
                                    (
                                        <div>
                                        </div>
                                    )
                                }
                                <div className='mt-2'>
                                    <p><span className='font-bold'>Description: </span>{TurtleMetadata[pageid-1].description}</p>
                                    <div className='flex flex-row items-center'>
                                        <p className='mr-[0.35em] font-bold'>Element:</p>
                                        <p className='mr-[0.35em] font-bold' style={{'color': elementcolor}}>{TurtleMetadata[pageid-1].attributes[1].value}</p>
                                        <img className='flex my-auto w-10 h-10' src={elementicon} alt="" />
                                    </div>
                                    {/*
                                    <div className='flex flex-row mt-4'>
                                        <div className='flex flex-col mr-3'>
                                            <p>Background:</p>
                                            <p>Shell:</p>
                                            <p>Shellbrim:</p>
                                            <p>Eyes:</p>
                                            <p>Hat:</p>
                                            <p>Mouth:</p>
                                            <p>Artifact:</p>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p>gegewg</p>
                                            <p>ewgewg</p>
                                            <p>eghrwhrerhheehrg</p>
                                            <p>ggwhreyu</p>
                                            <p>wqd</p>
                                            <p>ghhhh</p>
                                            <p>gewgwegewgwqw</p>
                                        </div>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TurtleRenderer;