import bgimg from '../assets/images/lake.png';
import MintpageTurtles from '../assets/images/mintpageturtles.png';
import FireHatch from '../assets/images/FireHatch.png';
import PoisonHatch from '../assets/images/PoisonHatch.png';
import './mint.css'
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CountdownTimer from '../components/CountdownTimer'

function Mint() {
    const data = useSelector((state) => state.data);

    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: ""
    });

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <div className='mt-24 sm:mt-28 md:mt-32 lg:mt-40 sm:mb-3 md:mb-12 flex justify-center items-center'>
            <img className='fixed top-0 left-0 w-full h-full object-cover z-[-1]' src={bgimg} alt="" />
            <div className='z-[1] min-h-[80vh] p-4 rounded-3xl w-[95%] sm:w-[80%] md:w-[65%] lg:w-[50%] bg-[rgba(193,240,188,0.9)]'>
                <div className='w-[85%] lg:w-[80%] xl:w-[70%] 2xl:w-[60%] max-w-[500px] flex justify-center text-center mx-auto translate-y-[1em]'>
                    <img src={MintpageTurtles} alt="Seven different Tubby Turtles" />
                </div>
                <h1 className='text-xl md:text-2xl font-bold flex text-center justify-center mt-6 px-4'>Get your own Tubby Turtle!</h1>
                <h2 className='text-lg md:text-xl flex text-center justify-center'>0 / 2100 have been minted</h2>
                <div className='flex flex-col justify-center'>
                    <CountdownTimer />
                    {/*<h1 className='text-xl'>Public minting will open up soon...</h1>*/}
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='FireHatch w-[calc(7em+3vw)] pt-14'>
                        <img classname='' src={FireHatch} alt="" />
                    </div>
                    <div className='PoisonHatch w-[calc(7em+3vw)] pt-14'>
                        <img classname='' src={PoisonHatch} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mint;