import bgimg from '../assets/images/mintbg.png';
import { connect } from '../redux/blockchain/blockchainActions';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../redux/data/dataActions';
import './nftviewer.css'
import twitterlogo from '../assets/images/twitterlogo.png'
import marketicon from '../assets/images/marketicon.png'
import telegramlogo from '../assets/images/telegramlogo.png'
import PulseLoader from 'react-spinners/PulseLoader'
import { db } from '../firebase';
import { onValue, ref } from 'firebase/database';
import TurtleMetadata from '../Nftdata/TurtleMetadata'
import Fire from '../assets/images/elementsymbols/Fire.png'
import Water from '../assets/images/elementsymbols/Water.png'
import Poison from '../assets/images/elementsymbols/Poison.png'
import Electric from '../assets/images/elementsymbols/Electric.png'
import Frost from '../assets/images/elementsymbols/Frost.png'
import Rock from '../assets/images/elementsymbols/Rock.png'
import Nature from '../assets/images/elementsymbols/Nature.png'
import AllElements from '../assets/images/elementsymbols/AllElements.png'
import { AiOutlineReload, AiOutlineSearch } from 'react-icons/ai'

function AllTurtles() {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (data["loading"] === false) {
            setLoading(false)
        }
    }, [data]);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        if (blockchain.account !== "") {
            dispatch(fetchData());
        }
    }, [blockchain.account, dispatch]);

    window.addEventListener('load', function () {
        startApp();
    })
    async function startApp() {
        window.ethereum.sendAsync({
            method: "eth_accounts",
            params: [],
            jsonrpc: "2.0",
            id: new Date().getTime()
        }, function (error, result) {
            if (result["result"] !== "") dispatch(connect());
        });
    }

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    useEffect(() => {
        setMintedamount(Number(data.totalSupply)+1);
    }, [data]);
    const [mintedamount, setMintedamount] = useState(0)

    function getElementTurtleIcon(input) {
        if (input === 'Fire') { return Fire }
        if (input === 'Water') { return Water }
        if (input === 'Poison') { return Poison }
        if (input === 'Electric') { return Electric }
        if (input === 'Frost') { return Frost }
        if (input === 'Rock') { return Rock }
        if (input === 'Nature') { return Nature }
    }

    const [Turtles, setTurtles] = useState(TurtleMetadata)
    const [Turtlesbase, setTurtlesbase] = useState(TurtleMetadata)
    //useEffect(() => {
    //    onValue(ref(db), snapshot => {
    //        const firebasedata = snapshot.val();
    //        setTurtles(firebasedata);
    //        //TurtleMetadata = firebasedata;
    //    })
    //}, []);

    console.log(Turtles)

    async function updateFirebaseTurtles() {
        onValue(ref(db), snapshot => {
            const firebasedata = snapshot.val();
            setTurtlesbase(firebasedata);
        })
    }

    function getAllFireElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Fire' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllWaterElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Water' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllPoisonElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Poison' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllElectricElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Electric' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllFrostElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Frost' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllRockElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Rock' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllNatureElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Nature' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllTurtles() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] !== '' && turtle.tokenId < mintedamount); setTurtles(result) }

    const [loadedTurtles, setLoadedTurtles] = useState(25)
    function loadMoreTurtles() {
        let amount = loadedTurtles;
        amount += 20
        if (amount > data["totalSupply"]) {
            amount = data["totalSupply"]
            amount = parseInt(amount)+1
        }
        if (amount > 100) {
            amount = 100
        }
        setLoadedTurtles(amount)
    }

    function searchturtle() {
        let search = document.getElementById('turtlesearch').value;
        let totalsup = parseInt(data.totalSupply)
        if (parseInt(search) < totalsup+1) {
            const result = Turtlesbase.filter(turtle => 
            parseInt(turtle.tokenId) === parseInt(search));
            setTurtles(result);
            let texthtml = document.getElementById('responsetext')
            texthtml.innerText = ''
        }
        else if (search === "") {
            const result = Turtlesbase.filter(turtle => 
                parseInt(turtle.tokenId) < totalsup+1);
                setTurtles(result);
        }
        else {
            let responsetext = 'To search for a specific turtle you have to put in a valid ID of a turtle that has already been minted.'
            let texthtml = document.getElementById('responsetext')
            texthtml.innerText = responsetext
        }
    }


    window.onscroll = function() {
        let allturtlesbody = document.getElementsByClassName('allturtlesbody')
        allturtlesbody = allturtlesbody[0]
        if ((window.innerHeight + Math.ceil(window.pageYOffset)) >= allturtlesbody.offsetHeight) {
        loadMoreTurtles()
        }
    }

    return (
        <div className="flex flex-col mx-auto w-[96%] md:w-[88%] lg:w-[83%] xl:w-[76%] h-[calc(100vh-100px)] mt-[95px] sm:mt-[110px] md:mt-[135px] lg:mt-[160px] mb-6 sm:mb-12 md:mb-20 lg:mb-28">
            <img className='fixed top-0 left-0 w-full h-full object-cover z-[-1]' src={bgimg} alt="" />

            <div className='rounded-2xl flex flex-col bg-[rgb(231,241,255)] mb-4 sm:mb-7 md:mb-10 lg:mb-12 xl:mb-14'>
                <div className='rounded-2xl w-full h-[55px] sm:h-[65px] md:h-[75px] flex flex-row'>
                    <Link to='/sea/all' className='rounded-tl-2xl bg-[rgb(231,241,255)] font-bold w-1/2 h-full justify-center flex items-center text-lg sm:text-xl md:text-2xl'>
                        All turtles
                    </Link>
                    <Link to='/sea/my' className='rounded-tr-2xl bg-[rgb(201,226,255)] font-bold w-1/2 h-full justify-center flex items-center text-lg sm:text-xl md:text-2xl'>
                        My turtles
                    </Link>
                </div>
                <div>
                {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <div className='flex flex-col justify-center'>
                            <div className='h-[70px]'>
                            <button className='text-base sm:text-lg md:text-xl mt-3 mb-1 flex pl-1 pr-2 mx-auto justify-center rounded-lg sm:rounded-xl items-center
                            bg-green-500 border-b-[3px] md:border-b-[5px] border-[rgb(24,136,65)] hover:brightness-[1.05] md:active:border-b-[3px] md:active:translate-y-[2px] active:border-b-[1px] active:translate-y-[2px]' onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(connect());
                                    getData();
                                }}>

                                <svg className="metamaskicon w-[35px] h-[35px] sm:w-[40px] sm:h-[40px] md:w-[45px] md:h-[45px]" strokeLinejoin="round" viewBox="0 0 318.6 318.6" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m274.1 35.5-99.5 73.9 18.4-43.6z" fill="#e2761b" stroke="#e2761b"></path><g fill="#e4761b" stroke="#e4761b"><path d="m44.4 35.5 98.7 74.6-17.5-44.3zm193.9 171.3-26.5 40.6 56.7 15.6 16.3-55.3z"></path><path d="m33.9 207.7 16.2 55.3 56.7-15.6-26.5-40.6zm69.7-69.5-15.8 23.9 56.3 2.5-2-60.5z"></path><path d="m214.9 138.2-39-34.8-1.3 61.2 56.2-2.5zm-108.1 109.2 33.8-16.5-29.2-22.8zm71.1-16.5 33.9 16.5-4.7-39.3z"></path></g><path d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z" fill="#d7c1b3" stroke="#d7c1b3"></path><path d="m138.8 193.5-28.2-8.3 19.9-9.1zm40.9 0 8.3-17.4 20 9.1z" fill="#233447" stroke="#233447"></path><path d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z" fill="#cd6116" stroke="#cd6116"></path><path d="m87.8 162.1 23.6 46-0.8-22.9zm120.3 23.1-1 22.9 23.7-46zm-64-20.6-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0-2.7 18 1.2 45 6.7-34.1z" fill="#e4751f" stroke="#e4751f"></path><path d="m179.8 193.5-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3 0.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z" fill="#f6851b" stroke="#f6851b"></path><path d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z" fill="#c0ad9e" stroke="#c0ad9e"></path><path d="m177.9 230.9-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z" fill="#161616" stroke="#161616"></path><path d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z" fill="#763d16" stroke="#763d16"></path><path d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z" fill="#f6851b" stroke="#f6851b"></path>
                                </svg>
                                Connect
                            </button>
                            </div>
                            
                            {blockchain.errorMsg !== "" ? (
                                <>
                                    <h2 className='text-center mb-2 errormsg'>{blockchain.errorMsg}</h2>
                                </>
                            ) : null}
                        </div>
                    ) : (
                        <div className='w-full z-[0] allturtlesbody' id='viewerbodyusersturtles'>
                        <div className='flex flex-col'>
                            
                            <div className='flex justify-center py-3 md:py-6'>
                                <button className='text-sm md:text-base lg:text-lg bg-blue-300 hover:brightness-110 rounded-md w-[125px] md:w-[145px] lg:w-[155px] px-[8px] md:px-[12px] py-[5px] md:py-[6px]'><a href='/transfer'>Transfer a turtle</a></button>
                            </div>
                            <div className='flex flex-row justify-center mb-3 md:mb-6 mt-3 md:mt-6 px-1 sm:px-3 mx-4 sm:mx-6 md:mx-8 lg:mx-10 xl:mx-12 py-3 md:py-4 rounded-xl bg-white'>
                                <button id='button1' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllTurtles}>
                                    <img className='rounded-full' src={AllElements} alt="" />
                                </button>
                                <button id='button2' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllFireElem}>
                                    <img src={Fire} alt="" />
                                </button>
                                <button id='button3' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllWaterElem}>
                                    <img src={Water} alt="" />
                                </button>
                                <button id='button4' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllPoisonElem}>
                                    <img src={Poison} alt="" />
                                </button>
                                <button id='button5' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllElectricElem}>
                                    <img src={Electric} alt="" />
                                </button>
                                <button id='button6' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllFrostElem}>
                                    <img src={Frost} alt="" />
                                </button>
                                <button id='button7' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllRockElem}>
                                    <img src={Rock} alt="" />
                                </button>
                                <button id='button8' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllNatureElem}>
                                    <img src={Nature} alt="" />
                                </button>
                            </div>
                            <div className='flex flex-col mx-auto mb-3 md:mb-6'>
                                <div className='flex justify-center'>
                                    <input className='text-sm md:text-base lg:text-lg border-[#CED4DA] hover:border-[#228BE6] border-2 rounded-md px-[5px] py-[3px]' id='turtlesearch' type="text" />
                                    <button onClick={searchturtle} className='bg-blue-300 hover:brightness-110 rounded-md px-2 ml-3 flex flex-row items-center'><AiOutlineSearch className='my-auto' />Search</button>
                                </div>
                                <div className='flex flex-row justify-center'>
                                    <p id='responsetext'></p>
                                </div>
                            </div>
                        </div>
                        {Turtles !== [] && Turtles !== undefined ? (
                        <div className='nftcontainer flex flex-wrap justify-center rounded-xl p-4 bg-[rgb(208,230,255)]'>
                            
                            {(Turtles.map(turtle => (
                                <div className='nftcard hover:scale-[1.08] transition-all w-[200px] bg-white rounded-xl m-4'>
                                    <Link to={`/${turtle.tokenId}`}>
                                        <img className='elementicon absolute w-[3.75em] -translate-x-4 -translate-y-5 rounded-full' src={getElementTurtleIcon(turtle.attributes[1]["value"])} alt="" />
                                    </Link>
                                    <Link to={`/${turtle.tokenId}`}>
                                        <img className='nftimg w-[calc(100%-14px)] h-auto rounded-xl flex mx-auto m-[7px]' src={turtle.image} alt="" />
                                    </Link>
                                    <div className='flex flex-col mb-2'>
                                        <div className='nftinfocardtext'>
                                            <h2 className='text-center text-base sm:text-lg md:text-xl'>#{turtle.tokenId}</h2>
                                            <h2 className='turtlename overflow-hidden text-center leading-[1.4rem] text-[1em]'>{turtle.name}</h2>
                                        </div>
                                    </div>
                                </div>
                            )))}
                            
                        </div>
                        ) : (<></>)}
                        </div>
                    )}
                    
                    <div className='w-full z-[0]' id='viewerbodyusersturtles'>
                        

                        {loading ? 
                        <div className='flex flex-col justify-center items-center mb-4'>
                            <p className='my-2 text-xl'>Please wait, loading in turtles...</p>
                            <PulseLoader loading={loading} color="#4d7cff" size={25} />
                        </div>
                        : (
                        <div className='flex justify-center my-3'>
                            <button onClick={loadMoreTurtles} className='flex justify-center rounded-md w-[250px] h-[40px] text-xl bg-blue-300'>
                                <div className='mx-auto my-auto flex flex-row'>
                                    <div>
                                    Load more turtles
                                    </div>
                                    <div>
                                        <AiOutlineReload className='mt-1 ml-[5px]' />
                                    </div>
                                </div>
                            </button>
                        </div>
                        )}
                    </div>
                </div>
            </div>
            <footer className='mb-[20px] flex justify-center relative sm:bottom-1 md:bottom-3 lg:bottom-5 z-50 w-full h-[100px]'>
                <div className='mb-[20px] flex flex-row w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] h-full place-content-around'>
                    <a href='https://twitter.com/TurtlesTubby' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(50,168,241,0.9)] transition-all border-[rgba(41,136,196,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                        <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={twitterlogo} alt="" />
                        <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Twitter</h1>
                    </a>

                    <a href='https://energywebnfts.com/markets' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(54,151,44,0.9)] transition-all border-[rgba(44,124,37,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                        <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={marketicon} alt="" />
                        <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Markets</h1>
                    </a>

                    <a href='https://t.me/susu_market' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(50,168,241,0.9)] transition-all border-[rgba(41,136,196,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                        <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={telegramlogo} alt="" />
                        <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Telegram</h1>
                    </a>
                </div>
            </footer>
        </div>
    )};

export default AllTurtles;

// <a className='relative text-xl md:text-2xl w-[65px] md:w-[80px] flex text-center px-3 py-1 mx-auto justify-center rounded-2xl items-center
// bg-green-500 border-b-[5px] border-[rgb(24,136,65)] hover:brightness-[1.05] active:border-b-[3px] active:translate-y-[3px] active:mt-[2px]' id='playbutton' href="https://play.tubbyturtles.com/">Play</a>