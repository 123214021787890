const MintingSchedule = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            version="1.1"
            viewBox="0 0 240.771 129.646"
        >
            <g
                fill="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.852"
                display="inline"
            >
                <path stroke="#e23846" d="M16.615 115.52h15.481"></path>
                <path stroke="#3059ff" d="M53.452 110.044H32.096v5.568"></path>
                <path stroke="#934cff" d="M79.638 99.61H53.452v10.617"></path>
                <path stroke="#ffe53f" d="M110.483 84.435H79.608l.03 15.176"></path>
                <path stroke="#76ebed" d="M147.382 63.362h-36.899v21.073"></path>
                <path stroke="#ff8b26" d="M189.298 37.562h-41.916v25.853"></path>
                <path stroke="#8cff5b" d="M235.99 6.664h-46.692V37.53"></path>
            </g>
            <g
                strokeWidth="0.265"
                display="inline"
                fontFamily="Roboto"
                fontSize="8.819"
            >
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="15.694"
                    y="126.451"
                >
                    <tspan x="15.694" y="126.451" strokeWidth="0.265" fontSize="8.819">
                        100
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="172.618"
                    y="9.818"
                >
                    <tspan x="172.618" y="9.818" strokeWidth="0.265" fontSize="8.819">
                        3.6
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="131.086"
                    y="40.595"
                >
                    <tspan x="130.3" y="40.595" strokeWidth="0.265" fontSize="8.819">
                        3.0
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="93.079"
                    y="66.277"
                >
                    <tspan x="93.3" y="66.277" strokeWidth="0.265" fontSize="8.819">
                        2.5
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="64.346"
                    y="87.081"
                >
                    <tspan x="64" y="87.081" strokeWidth="0.265" fontSize="8.819">
                        2.1
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="38.402"
                    y="102.643"
                >
                    <tspan x="37.9" y="102.643" strokeWidth="0.265" fontSize="8.819">
                        1.8
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="17.552"
                    y="111.999"
                >
                    <tspan x="17.0" y="111.999" strokeWidth="0.265" fontSize="8.819">
                        1.6
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="2.032"
                    y="118.196"
                >
                    <tspan x="1.3" y="118.196" strokeWidth="0.265" fontSize="8.819">
                        1.5
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="34.924"
                    y="121.532"
                >
                    <tspan x="34.924" y="121.532" strokeWidth="0.265" fontSize="8.819">
                        200
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="58.152"
                    y="110.653"
                >
                    <tspan x="58.152" y="110.653" strokeWidth="0.265" fontSize="8.819">
                        250
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="86.394"
                    y="96.027"
                >
                    <tspan x="86.394" y="96.027" strokeWidth="0.265" fontSize="8.819">
                        300
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="121.085"
                    y="75.293"
                >
                    <tspan x="121.085" y="75.293" strokeWidth="0.265" fontSize="8.819">
                        350
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="159.73"
                    y="49.808"
                >
                    <tspan x="159.73" y="49.808" strokeWidth="0.265" fontSize="8.819">
                        400
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25", fontWeight: "bold" }}
                    x="205.249"
                    y="18.308"
                >
                    <tspan x="205.249" y="18.308" strokeWidth="0.265" fontSize="8.819">
                        450
                    </tspan>
                </text>
            </g>
        </svg>
    );
}

export default MintingSchedule;