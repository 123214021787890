import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Fire from '../assets/images/elementsymbols/Fire.png'
import Water from '../assets/images/elementsymbols/Water.png'
import Poison from '../assets/images/elementsymbols/Poison.png'
import Electric from '../assets/images/elementsymbols/Electric.png'
import Frost from '../assets/images/elementsymbols/Frost.png'
import Rock from '../assets/images/elementsymbols/Rock.png'
import Nature from '../assets/images/elementsymbols/Nature.png'

import FireLit from '../assets/images/elementsymbols/ElementsLitUp/Fire.png'
import WaterLit from '../assets/images/elementsymbols/ElementsLitUp/Water.png'
import PoisonLit from '../assets/images/elementsymbols/ElementsLitUp/Poison.png'
import ElectricLit from '../assets/images/elementsymbols/ElementsLitUp/Electric.png'
import FrostLit from '../assets/images/elementsymbols/ElementsLitUp/Frost.png'
import RockLit from '../assets/images/elementsymbols/ElementsLitUp/Rock.png'
import NatureLit from '../assets/images/elementsymbols/ElementsLitUp/Nature.png'

import { BsArrowLeft } from 'react-icons/bs'

const Elements = () => {
    const [color, setColor] = useState('');

    useEffect(() => {
        let currentelementpage = (window.location.pathname.split('/').pop())
        if (currentelementpage === 'fire') {
            setColor('rgba(224,83,95,0.25)');
            let elementtext = 'Fire: the element of energy, warmth, and passion.'
            document.getElementById('elementtext').textContent = elementtext
            let elementicon = Fire
            document.getElementById('elementicon').src = elementicon
            document.getElementById('elementicon').style.display = 'block'
            let elementiconlitup = FireLit
            document.getElementById('elementiconlitup').src = elementiconlitup
            document.getElementById('elementiconlitup').style.display = 'block'
        }
        if (currentelementpage === 'water') {
            setColor('rgba(103,132,251,0.25)')
            let elementtext = 'Water: the element of peace, trust and motion.'
            document.getElementById('elementtext').textContent = elementtext
            let elementicon = Water
            document.getElementById('elementicon').src = elementicon
            document.getElementById('elementicon').style.display = 'block'
            let elementiconlitup = WaterLit
            document.getElementById('elementiconlitup').src = elementiconlitup
            document.getElementById('elementiconlitup').style.display = 'block'
        }
        if (currentelementpage === 'poison') {
            setColor('rgba(179,130,252,0.25)')
            let elementtext = 'Poison: the element of death, cruelty and mysteriousness.'
            document.getElementById('elementtext').textContent = elementtext
            let elementicon = Poison
            document.getElementById('elementicon').src = elementicon
            document.getElementById('elementicon').style.display = 'block'
            let elementiconlitup = PoisonLit
            document.getElementById('elementiconlitup').src = elementiconlitup
            document.getElementById('elementiconlitup').style.display = 'block'
        }
        if (currentelementpage === 'electric') {
            setColor('rgba(251,233,118,0.25)')
            let elementtext = 'Electric: the element of energy, optimism and hope.'
            document.getElementById('elementtext').textContent = elementtext
            let elementicon = Electric
            document.getElementById('elementicon').src = elementicon
            document.getElementById('elementicon').style.display = 'block'
            let elementiconlitup = ElectricLit
            document.getElementById('elementiconlitup').src = elementiconlitup
            document.getElementById('elementiconlitup').style.display = 'block'
        }
        if (currentelementpage === 'frost') {
            setColor('rgba(169,232,234,0.25)')
            let elementtext = 'Frost: the element of order, confidence and calmness.'
            document.getElementById('elementtext').textContent = elementtext
            let elementicon = Frost
            document.getElementById('elementicon').src = elementicon
            document.getElementById('elementicon').style.display = 'block'
            let elementiconlitup = FrostLit
            document.getElementById('elementiconlitup').src = elementiconlitup
            document.getElementById('elementiconlitup').style.display = 'block'
        }
        if (currentelementpage === 'rock') {
            setColor('rgba(251,166,93,0.25)')
            let elementtext = 'Rock: the element of reliability, sturdiness and strength.'
            document.getElementById('elementtext').textContent = elementtext
            let elementicon = Rock
            document.getElementById('elementicon').src = elementicon
            document.getElementById('elementicon').style.display = 'block'
            let elementiconlitup = RockLit
            document.getElementById('elementiconlitup').src = elementiconlitup
            document.getElementById('elementiconlitup').style.display = 'block'
        }
        if (currentelementpage === 'nature') {
            setColor('rgba(177,252,145,0.25)')
            let elementtext = 'Nature: the element of balance, stability and endurance.'
            document.getElementById('elementtext').textContent = elementtext
            let elementicon = Nature
            document.getElementById('elementicon').src = elementicon
            document.getElementById('elementicon').style.display = 'block'
            let elementiconlitup = NatureLit
            document.getElementById('elementiconlitup').src = elementiconlitup
            document.getElementById('elementiconlitup').style.display = 'block'
        }
        document.getElementById('bgelementspage').style.backgroundColor = color
    }, [color]);

    return (
        <div id='bgelementspage' className='w-[100vw] h-[100vh]'>
            <div className='w-full h-full pt-[100px] sm:pt-[110px] md:pt-[125px] lg:pt-[140px] flex flex-col'>
                <Link className='items-center flex flex-row text-2xl mb-[25px] h-[53px] shadow-md mx-auto py-2 px-5 bg-[#F0A657] border-b-[5px] border-[#C48747] active:translate-y-[3px] active:border-b-[2px] rounded-lg left-10' to='/elements'>
                    <BsArrowLeft className='mr-[3px]' />Back
                </Link>
                <div className='mx-auto flex justify-center items-center flex-col'>
                    <img id='elementiconlitup' className='rounded-3xl hidden w-[80%] sm:w-[70%] md:w-[60%] h-auto' src="" alt="" />
                    <p className='mt-2 mb-1 w-[90%] text-3xl text-center' id='elementtext'></p>
                    <img id='elementicon' className='hidden w-[90px] h-[90px]' src="" alt="" />
                </div>
            </div>
        </div>
    );
}

export default Elements;