import twitterlogo from '../assets/images/twitterlogo.png'
import marketicon from '../assets/images/marketicon.png'
import telegramlogo from '../assets/images/telegramlogo.png'
import { useLocation } from "react-router-dom";

const Footer = () => {
    const { pathname } = useLocation();
    if (pathname === '/mint' || pathname === '/stats') {
    return (
    <footer className='flex justify-center relative sm:mt-1 md:mt-4 lg:mt-5 sm:bottom-1 md:bottom-3 lg:bottom-5 z-50 w-full h-[100px]'>
        <div className='flex flex-row w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] h-full place-content-around'>
            <a href='https://twitter.com/TurtlesTubby' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(50,168,241,0.9)] transition-all border-[rgba(41,136,196,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={twitterlogo} alt="" />
                <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Twitter</h1>
            </a>

            <a href='https://energywebnfts.com/markets' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(54,151,44,0.9)] transition-all border-[rgba(44,124,37,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={marketicon} alt="" />
                <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Markets</h1>
            </a>

            <a href='https://t.me/+7TetOgJOjVVkZDRk' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(50,168,241,0.9)] transition-all border-[rgba(41,136,196,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={telegramlogo} alt="" />
                <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Telegram</h1>
            </a>
        </div>
    </footer>
    )}
    else {
        return null
    }
}

export default Footer;