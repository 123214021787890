import bgimg from '../assets/images/mintbg.png';
import { connect } from '../redux/blockchain/blockchainActions';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../redux/data/dataActions';
import { db } from '../firebase';
import { onValue, ref } from 'firebase/database';
import store from '../redux/store'
import './nftviewer.css'
import twitterlogo from '../assets/images/twitterlogo.png'
import marketicon from '../assets/images/marketicon.png'
import telegramlogo from '../assets/images/telegramlogo.png'
import PulseLoader from 'react-spinners/PulseLoader'

import TurtleMetadata from '../Nftdata/TurtleMetadata'
import Fire from '../assets/images/elementsymbols/Fire.png'
import Water from '../assets/images/elementsymbols/Water.png'
import Poison from '../assets/images/elementsymbols/Poison.png'
import Electric from '../assets/images/elementsymbols/Electric.png'
import Frost from '../assets/images/elementsymbols/Frost.png'
import Rock from '../assets/images/elementsymbols/Rock.png'
import Nature from '../assets/images/elementsymbols/Nature.png'
import AllElements from '../assets/images/elementsymbols/AllElements.png'
//import Unknown from '../assets/images/elementsymbols/Unknown.png'
import useColorChange from 'use-color-change'

import shelllogo from '../assets/images/shellcoin.png'

function MyTurtles() {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        if (blockchain.account !== "") {
            dispatch(fetchData());
        }
    }, [blockchain.account, dispatch]);

    window.addEventListener('load', function () {
        startApp();
    })
    async function startApp() {
        window.ethereum.sendAsync({
            method: "eth_accounts",
            params: [],
            jsonrpc: "2.0",
            id: new Date().getTime()
        }, function (error, result) {
            if (result["result"] !== "") dispatch(connect());
        });
    }

    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        STAKINGCONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: ""
    });

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
        console.log(data)
    }, []);

    // ------------------------------------------------ //

    function getElementTurtleIcon(input) {
        if (input === 'Fire') { return Fire }
        if (input === 'Water') { return Water }
        if (input === 'Poison') { return Poison }
        if (input === 'Electric') { return Electric }
        if (input === 'Frost') { return Frost }
        if (input === 'Rock') { return Rock }
        if (input === 'Nature') { return Nature }
    }

    const [Turtles, setTurtles] = useState([])

    function updateUserOwned() {
        let tokensHolderOwns = data["turtleOwner"];
        let tokensStaked = data["turtlesStaked"];

        let newturtleslist = []
        if (tokensHolderOwns.length !== 0 || tokensStaked.length !== 0) {
            for (let g = 0; g < TurtleMetadata.length; g++) {
                for (let h = 0; h < tokensHolderOwns.length; h++) {
                    const element = tokensHolderOwns[h];
                    if (parseInt(element) === TurtleMetadata[g].tokenId) {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
                for (let v = 0; v < tokensStaked.length; v++) {
                    let element = tokensStaked[v];
                    if (parseInt(element) === TurtleMetadata[g].tokenId) {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
        }
        setTurtles(newturtleslist)
    }

    useEffect(() => {
        updateUserOwned();
        stakedOrNot();
    }, [store.getState()["data"]["turtleOwner"], store.getState()["data"]["turtlesStaked"]]);

    function approveShellsToTubbyTurtles() {
        let approveAmount = '500000000000000000000';
        let approveAmountStaking = '5000000000000000000000';
        let cost = 0;
        let totalCostWei = String(cost);

        blockchain.stakingSmartContract.methods.approve(CONFIG.CONTRACT_ADDRESS, approveAmount)
            .send({
                to: CONFIG.STAKINGCONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
        blockchain.stakingSmartContract.methods.approve(CONFIG.STAKINGCONTRACT_ADDRESS, approveAmountStaking)
            .send({
                to: CONFIG.STAKINGCONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
    }

    async function changeTurtleName() {
        let turtleid = document.getElementById('inputId').value
        let turtlenewname = document.getElementById('inputName').value
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.smartContract.methods.changeTurtleName(turtleid, turtlenewname)
            .send({
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
    }

    async function stakeTurtle(tokenId) {
        let allstakebuttons = document.getElementsByClassName('stakebutton')
        let allstaketext = document.getElementsByClassName('staketext')
        let allstakeimage = document.getElementsByClassName('stakeimage')
        let alltokenids = document.getElementsByClassName('turtletokenid')
        let allunstakebuttons = document.getElementsByClassName('unstakebutton')

        console.log(`staking turtle with id: ${tokenId}...`)
        blockchain.stakingSmartContract.methods.stakeByIds([tokenId])
            .send({
                to: CONFIG.STAKINGCONTRACT_ADDRESS,
                from: blockchain.account
            }).then((receipt) => {
                let returnvalue = tokenId
                for (let index = 0; index < alltokenids.length; index++) {
                    if (parseInt(returnvalue) === parseInt(alltokenids[index].innerHTML.substring(1))) {
                        let element = allstakebuttons[index];
                        element.disabled = true;
                        element.style.backgroundColor = '#D1F9E0'
                        element.style.borderColor = '#BBF2CF'

                        let element2 = allstaketext[index];
                        element2.innerText = 'Staked'

                        let element3 = allstakeimage[index];
                        element3.src = ''
                        element3.style.width = '0px'
                        element3.style.height = '0px'
                        element3.style.marginLeft = '0px'
                    }
                }
                let returnvalue2 = tokenId
                for (let index = 0; index < alltokenids.length; index++) {
                    if (parseInt(returnvalue2) === parseInt(alltokenids[index].innerHTML.substring(1))) {
                        let element = allunstakebuttons[index];
                        element.innerText = 'Unstake'
                        element.disabled = false;
                        element.style.backgroundColor = '#FCA5A5'
                        element.style.borderColor = '#F87171'
                    }
                }
            }
        )
    }

    async function unstakeTurtle(tokenId) {
        let allapprovebuttons = document.getElementsByClassName('approvebutton')
        let allstakebuttons = document.getElementsByClassName('stakebutton')
        let allstaketext = document.getElementsByClassName('staketext')
        let allstakeimage = document.getElementsByClassName('stakeimage')
        let allunstakebuttons = document.getElementsByClassName('unstakebutton')
        let alltokenids = document.getElementsByClassName('turtletokenid')

        console.log(`unstaking turtle with id: ${tokenId}...`)
        blockchain.stakingSmartContract.methods.unstakeByIds([tokenId])
            .send({
                to: CONFIG.STAKINGCONTRACT_ADDRESS,
                from: blockchain.account
            }).then((receipt) => {
                let returnvalue = tokenId
                for (let index = 0; index < alltokenids.length; index++) {
                    if (parseInt(returnvalue) === parseInt(alltokenids[index].innerHTML.substring(1))) {
                        let element = allstakebuttons[index];
                        element.disabled = false;
                        element.style.backgroundColor = '#86EFAC'
                        element.style.borderColor = '#4ADE80'

                        let element2 = allstaketext[index];
                        element2.innerText = 'Stake'

                        let element3 = allstakeimage[index];
                        element3.src = shelllogo
                        element3.style.width = '1rem'
                        element3.style.height = '1rem'
                        element3.style.marginLeft = '3px'

                        let element20 = allapprovebuttons[index];
                        element20.innerText = 'Approve'
                        element20.disabled = false;
                        element20.style.backgroundColor = '#93C5FD'
                        element20.style.borderColor = '#60A5FA'
                    }
                }
                let returnvalue2 = tokenId
                for (let index = 0; index < alltokenids.length; index++) {
                    if (parseInt(returnvalue2) === parseInt(alltokenids[index].innerHTML.substring(1))) {
                        let element = allunstakebuttons[index];
                        element.innerText = 'Unstaked'
                        element.disabled = true;
                        element.style.backgroundColor = '#FDD9D9'
                        element.style.borderColor = '#FCC4C4'
                    }
                }
            }
        )
    }

    async function approveTurtle(tokenId) {
        let allapprovebuttons = document.getElementsByClassName('approvebutton')
        let allstakebuttons = document.getElementsByClassName('stakebutton')
        let alltokenids = document.getElementsByClassName('turtletokenid')

        blockchain.smartContract.methods.approve(CONFIG.STAKINGCONTRACT_ADDRESS, tokenId)
            .send({
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account
            }).then((receipt) => {
                let returnvalue = parseInt(receipt["events"]["Approval"]["returnValues"][2])
                for (let index = 0; index < alltokenids.length; index++) {
                    if ((returnvalue) === parseInt(alltokenids[index].innerHTML.substring(1))) {
                        let element = allapprovebuttons[index];
                        element.innerText = 'Approved'
                        element.disabled = true;
                        element.style.backgroundColor = '#DBEBFE'
                        element.style.borderColor = '#CAE1FD'

                        let element2 = allstakebuttons[index];
                        element2.disabled = false;
                        element2.style.backgroundColor = '#86EFAC'
                        element2.style.borderColor = '#4ADE80'
                    }
                }
            }
        )
    }

    async function getAllFireElem() {
        let tokensHolderOwns = data["turtleOwner"];
        let tokensStaked = data["turtlesStaked"];

        let newturtleslist = []
        for (let g = 0; g < TurtleMetadata.length; g++) {
            for (let h = 0; h < tokensHolderOwns.length; h++) {
                const element = tokensHolderOwns[h];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if ("Fire" === TurtleMetadata[g].attributes[1].value) {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
            for (let v = 0; v < tokensStaked.length; v++) {
                let element = tokensStaked[v];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if (TurtleMetadata[g].attributes[1].value === 'Fire') {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
        }
        setTurtles(newturtleslist)
    }

    async function getAllWaterElem() {
        let tokensHolderOwns = data["turtleOwner"];
        let tokensStaked = data["turtlesStaked"];

        let newturtleslist = []
        for (let g = 0; g < TurtleMetadata.length; g++) {
            for (let h = 0; h < tokensHolderOwns.length; h++) {
                const element = tokensHolderOwns[h];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if ("Water" === TurtleMetadata[g].attributes[1].value) {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
            for (let v = 0; v < tokensStaked.length; v++) {
                let element = tokensStaked[v];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if (TurtleMetadata[g].attributes[1].value === 'Water') {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
        }
        setTurtles(newturtleslist)
    }

    async function getAllPoisonElem() {
        let tokensHolderOwns = data["turtleOwner"];
        let tokensStaked = data["turtlesStaked"];

        let newturtleslist = []
        for (let g = 0; g < TurtleMetadata.length; g++) {
            for (let h = 0; h < tokensHolderOwns.length; h++) {
                const element = tokensHolderOwns[h];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if ("Poison" === TurtleMetadata[g].attributes[1].value) {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
            for (let v = 0; v < tokensStaked.length; v++) {
                let element = tokensStaked[v];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if (TurtleMetadata[g].attributes[1].value === 'Poison') {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
        }
        setTurtles(newturtleslist)
    }

    async function getAllElectricElem() {
        let tokensHolderOwns = data["turtleOwner"];
        let tokensStaked = data["turtlesStaked"];

        let newturtleslist = []
        for (let g = 0; g < TurtleMetadata.length; g++) {
            for (let h = 0; h < tokensHolderOwns.length; h++) {
                const element = tokensHolderOwns[h];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if ("Electric" === TurtleMetadata[g].attributes[1].value) {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
            for (let v = 0; v < tokensStaked.length; v++) {
                let element = tokensStaked[v];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if (TurtleMetadata[g].attributes[1].value === 'Electric') {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
        }
        setTurtles(newturtleslist)
    }

    async function getAllFrostElem() {
        let tokensHolderOwns = data["turtleOwner"];
        let tokensStaked = data["turtlesStaked"];

        let newturtleslist = []
        for (let g = 0; g < TurtleMetadata.length; g++) {
            for (let h = 0; h < tokensHolderOwns.length; h++) {
                const element = tokensHolderOwns[h];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if ("Frost" === TurtleMetadata[g].attributes[1].value) {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
            for (let v = 0; v < tokensStaked.length; v++) {
                let element = tokensStaked[v];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if (TurtleMetadata[g].attributes[1].value === 'Frost') {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
        }
        setTurtles(newturtleslist)
    }

    async function getAllRockElem() {
        let tokensHolderOwns = data["turtleOwner"];
        let tokensStaked = data["turtlesStaked"];

        let newturtleslist = []
        for (let g = 0; g < TurtleMetadata.length; g++) {
            for (let h = 0; h < tokensHolderOwns.length; h++) {
                const element = tokensHolderOwns[h];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if ("Rock" === TurtleMetadata[g].attributes[1].value) {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
            for (let v = 0; v < tokensStaked.length; v++) {
                let element = tokensStaked[v];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if (TurtleMetadata[g].attributes[1].value === 'Rock') {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
        }
        setTurtles(newturtleslist)
    }

    async function getAllNatureElem() {
        let tokensHolderOwns = data["turtleOwner"];
        let tokensStaked = data["turtlesStaked"];

        let newturtleslist = []
        for (let g = 0; g < TurtleMetadata.length; g++) {
            for (let h = 0; h < tokensHolderOwns.length; h++) {
                const element = tokensHolderOwns[h];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if ("Nature" === TurtleMetadata[g].attributes[1].value) {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
            for (let v = 0; v < tokensStaked.length; v++) {
                let element = tokensStaked[v];
                if (parseInt(element) === TurtleMetadata[g].tokenId) {
                    if (TurtleMetadata[g].attributes[1].value === 'Nature') {
                        newturtleslist.push(TurtleMetadata[g])
                    }
                }
            }
        }
        setTurtles(newturtleslist)
    }

    async function stakedOrNot() {
        let tokensStaked = data["turtlesStaked"];
        let allstakebuttons = document.getElementsByClassName('stakebutton')
        let allunstakebuttons = document.getElementsByClassName('unstakebutton')
        let allstaketext = document.getElementsByClassName('staketext')
        let allstakeimage = document.getElementsByClassName('stakeimage')
        let alltokenids = document.getElementsByClassName('turtletokenid')
        let allapprovebuttons = document.getElementsByClassName('approvebutton')

        for (let index2 = 0; index2 < alltokenids.length; index2++) {
            for (let index3 = 0; index3 < tokensStaked.length; index3++) {
                if (alltokenids[index2].innerText.substring(1) === tokensStaked[index3]) {
                    let element7 = allstakebuttons[index2];
                    element7.disabled = true;
                    element7.style.backgroundColor = '#D1F9E0'
                    element7.style.borderColor = '#BBF2CF'

                    let element8 = allstaketext[index2];
                    element8.innerText = 'Staked';
                    element8.disabled = true;

                    let element13 = allstakeimage[index2];
                    element13.disabled = true;
                    element13.src = ''
                    element13.style.width = '0px'
                    element13.style.height = '0px'
                    element13.style.marginLeft = '0px'

                    let element6 = allapprovebuttons[index2];
                    element6.innerText = 'Approved'
                    element6.disabled = true;
                    element6.style.backgroundColor = '#DBEBFE'
                    element6.style.borderColor = '#CAE1FD'
                }
                else if (alltokenids[index2].innerText.substring(1) in tokensStaked === false && alltokenids[index2].innerText.substring(1) !== tokensStaked[index3]) {
                        let element16 = allapprovebuttons[index2];
                        element16.innerText = 'Approve'
                        element16.disabled = false;
                        element16.style.backgroundColor = '#93C5FD'
                        element16.style.borderColor = '#60A5FA'

                        let element17 = allstakebuttons[index2];
                        element17.disabled = false;
                        element17.style.backgroundColor = '#86EFAC'
                        element17.style.borderColor = '#4ADE80'

                        let element18 = allstaketext[index2];
                        element18.innerText = 'Stake';
                        element18.disabled = false;

                        let element19 = allstakeimage[index2];
                        element19.disabled = false;
                        element19.src = shelllogo;
                        element19.style.width = '1rem'
                        element19.style.height = '1rem'
                        element19.style.marginLeft = '3px'
                }
            }
        }
    }
    
    useEffect(() => {
        stakedOrNot()
    }, [Turtles]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (data["loading"] === false) {
            setLoading(false)
        }
        getStakedAmount()
        if (parseInt(data["approveAllowance"]) >! 0 && parseInt(data["approveContractAllowance"]) >! 0) {
            setshowapprove(true)
        }
    }, [data]);

    const [stakedamount, setStakedamount] = useState(0)
    function getStakedAmount() {
        setStakedamount(data["totalstakedamount"])
    }

    const [showapprove, setshowapprove] = useState(false);

    window.addEventListener('load', function () {
        setInterval(function(){   
            let allturtlecards = document.getElementsByClassName('nftcard')
            if(allturtlecards.length > 100) {
                window.location.reload();
            }
        }, 1000);
    })

    async function getEarnedRewards() {
        try {
            let earnedShells = await store
                .getState()
                .blockchain.stakingSmartContract.methods.getAllRewards(blockchain.account)
                .call();
            setEarned((earnedShells/1000000000000000000).toFixed(2))
        } catch (error) {
            let i;
        }
    }

    async function claimAllStakingRewards() {
        blockchain.stakingSmartContract.methods.claimAll()
        .send({
            to: CONFIG.STAKINGCONTRACT_ADDRESS,
            from: blockchain.account
        }).then((receipt) => {
            console.log(receipt)
        })
    }

    setInterval(function() {
        getEarnedRewards()
    }, 4000);

    const [earned, setEarned] = useState(0)

    const colorStyle = useColorChange(earned, {
        higher: "limegreen",
        lower: "crimson",
        duration: 2200
    });

    return (
        <div className="flex flex-col mx-auto w-[96%] md:w-[88%] lg:w-[83%] xl:w-[76%] h-[calc(100vh-100px)] mt-[95px] sm:mt-[110px] md:mt-[135px] lg:mt-[160px] mb-6 sm:mb-12 md:mb-20 lg:mb-28">
            <img className='fixed top-0 left-0 w-full h-full object-cover z-[-1]' src={bgimg} alt="" />

            <div className='rounded-2xl flex flex-col bg-[rgb(231,241,255)] mb-4 sm:mb-7 md:mb-10 lg:mb-12 xl:mb-14'>
                <div className='rounded-2xl w-full h-[75px] flex flex-row'>
                    <Link to='/sea/all' className='rounded-tl-2xl bg-[rgb(201,226,255)] font-bold w-1/2 h-full justify-center flex items-center text-2xl'>
                        All turtles
                    </Link>
                    <Link to='/sea/my' className='rounded-tr-2xl bg-[rgb(231,241,255)] font-bold w-1/2 h-full justify-center flex items-center text-2xl'>
                        My turtles
                    </Link>
                </div>
                <div>
                    {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <div className='flex flex-col justify-center'>
                            <div className='h-[70px]'>
                            <button className='mt-3 mb-1 flex pl-1 pr-2 mx-auto justify-center rounded-xl items-center
                            bg-green-500 border-b-[5px] border-[rgb(24,136,65)] hover:brightness-[1.05] active:border-b-[3px] active:translate-y-[3px]' onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(connect());
                                    getData();
                                }}>

                                <svg width="50px" height="50px" className="metamaskicon" strokeLinejoin="round" viewBox="0 0 318.6 318.6" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m274.1 35.5-99.5 73.9 18.4-43.6z" fill="#e2761b" stroke="#e2761b"></path><g fill="#e4761b" stroke="#e4761b"><path d="m44.4 35.5 98.7 74.6-17.5-44.3zm193.9 171.3-26.5 40.6 56.7 15.6 16.3-55.3z"></path><path d="m33.9 207.7 16.2 55.3 56.7-15.6-26.5-40.6zm69.7-69.5-15.8 23.9 56.3 2.5-2-60.5z"></path><path d="m214.9 138.2-39-34.8-1.3 61.2 56.2-2.5zm-108.1 109.2 33.8-16.5-29.2-22.8zm71.1-16.5 33.9 16.5-4.7-39.3z"></path></g><path d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z" fill="#d7c1b3" stroke="#d7c1b3"></path><path d="m138.8 193.5-28.2-8.3 19.9-9.1zm40.9 0 8.3-17.4 20 9.1z" fill="#233447" stroke="#233447"></path><path d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z" fill="#cd6116" stroke="#cd6116"></path><path d="m87.8 162.1 23.6 46-0.8-22.9zm120.3 23.1-1 22.9 23.7-46zm-64-20.6-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0-2.7 18 1.2 45 6.7-34.1z" fill="#e4751f" stroke="#e4751f"></path><path d="m179.8 193.5-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3 0.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z" fill="#f6851b" stroke="#f6851b"></path><path d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z" fill="#c0ad9e" stroke="#c0ad9e"></path><path d="m177.9 230.9-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z" fill="#161616" stroke="#161616"></path><path d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z" fill="#763d16" stroke="#763d16"></path><path d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z" fill="#f6851b" stroke="#f6851b"></path>
                                </svg>
                                Connect
                            </button>
                            </div>

                            {blockchain.errorMsg !== "" ? (
                                <>
                                    <h2 className='text-center mb-2 errormsg'>{blockchain.errorMsg}</h2>
                                </>
                            ) : null}
                        </div>
                    ) : (
                        <div className='px-5 pt-5 flex justify-center flex-col md:w-[90%] lg:w-[80%] mx-auto'>
                            <h2 className='text-lg text-center'>Below you can see all the Tubby Turtles you own.</h2>
                            <h2 className='text-lg text-center'>Staking a turtle costs <span className='font-bold'>50 Shells.</span><span><img className='w-[24px] h-[24px] my-auto -translate-y-[2px] inline' src={shelllogo}></img></span> You can earn shells by staking, or you can buy them on <span className='text-[#2D286B] hover:brightness-125 active:brightness-200 underline'><a href='https://carbonswap.exchange/#/swap'>Carbonswap</a></span>.</h2>
                            <h2 className='text-lg text-center'>If you want to stake your turtle you first have to approve the Shell token contract so it's able to use your Shell tokens.</h2>
                            <h2 className='text-lg text-center'>You don't need to approve before unstaking.</h2>

                            {showapprove === false ? (
                                <div className='my-2 pb-2 flex flex-col border-t-4 border-b-4 rounded-lg border-[#ffffff]'>
                                    <h2 className='text-lg text-center'>To perform actions like staking and name changing, you first need to have Shell tokens and you need to approve the contract so it's able to spend your Shells.</h2>
                                    <h2 className='md:w-[75%] md:mx-auto text-base text-center'>(The button below suggests 2 transaction; 1 for allowing the NFT contract to use Shell tokens, and 1 for allowing the staking contract to use your Shell tokens.)</h2>
                                    <div className='mx-auto h-[64px]'>
                                        <button className='bg-[#22C55E] border-[#188841] border-b-[5px] active:border-b-[2px] active:translate-y-[3px] text-2xl rounded-lg py-[5px] px-[10px] flex flex-row items-center' onClick={approveShellsToTubbyTurtles}>Approve Shells<img className='ml-[6px] my-auto w-11 h-11' src={shelllogo} /></button>
                                    </div>
                                </div>
                                ) : (
                                    <div></div>
                                )
                            }
                            <div className='h-[60px] sm:h-[51px] mx-auto bg-green-200 rounded-md py-[10px] px-3 flex flex-row'>
                                <p className='text-sm sm:text-xl my-auto'>Earned staking rewards: <span id='claimableamount' style={colorStyle}>{earned}</span><img className='ml-1 inline -translate-y-[2px] w-[18px] h-[18px] sm:w-[28px] sm:h-[28px]' src={shelllogo} /></p>
                                <button onClick={claimAllStakingRewards} className='text-base my-auto items-center sm:text-xl rounded-md ml-2 w-28 sm:w-24 px-2 flex justify-center h-full bg-[#22C55E] hover:brightness-110 border-b-[3px] border-[#188841] active:border-b-[1px] active:translate-y-[2px]'>Claim all</button>
                            </div>
                            <div className='flex mx-auto mt-2 mb-2'>
                                <div className='text-xl mr-2 flex flex-col text-center'>
                                    <p className='font-bold'>Staked turtles:</p>
                                    <p>{stakedamount}/2100</p>
                                </div>
                                <div className='text-xl ml-2 flex flex-col text-center'>
                                    <p className='font-bold'>Percentage of staked turtles:</p>
                                    <p>{(stakedamount/2100*100).toFixed(2)}%</p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div>
                        <div className='flex flex-col'>
                            <div className='flex flex-row justify-center px-1 sm:px-3 mx-4 sm:mx-6 md:mx-8 lg:mx-10 xl:mx-12 pb-4 pt-4 rounded-xl bg-white'>
                                <button className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={updateUserOwned}>
                                    <img className='rounded-full' src={AllElements} alt="" />
                                </button>
                                <button className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllFireElem}>
                                    <img src={Fire} alt="" />
                                </button>
                                <button className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllWaterElem}>
                                    <img src={Water} alt="" />
                                </button>
                                <button className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllPoisonElem}>
                                    <img src={Poison} alt="" />
                                </button>
                                <button className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllElectricElem}>
                                    <img src={Electric} alt="" />
                                </button>
                                <button className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllFrostElem}>
                                    <img src={Frost} alt="" />
                                </button>
                                <button className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllRockElem}>
                                    <img src={Rock} alt="" />
                                </button>
                                <button className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllNatureElem}>
                                    <img src={Nature} alt="" />
                                </button>
                            </div>
                        </div>
                        <div>
                        {loading === true && data !== "" ?
                        <div className='flex flex-col justify-center items-center mb-4'>
                            <p className='my-2 text-xl'>Please wait, loading in your turtles...</p>
                            <PulseLoader loading={loading} color="#4d7cff" size={25} />
                        </div>
                        : (
                            <div className='nftcontainer flex flex-wrap justify-center rounded-xl p-4 mt-2 bg-[rgb(201,226,255)]'>
                                {(Turtles.map(turtle => (
                                    <div className='nftcard hover:scale-[1.08] transition-all w-[200px] bg-white rounded-xl m-4'>
                                        <Link to={`/${turtle.tokenId}`}>
                                            <img className='elementicon absolute w-16 -translate-x-4 -translate-y-5 rounded-full' src={getElementTurtleIcon(turtle.attributes[1]["value"])} alt="" />
                                        </Link>
                                        <Link to={`/${turtle.tokenId}`}>
                                            <img className='nftimg w-[calc(100%-16px)] h-auto rounded-xl flex mx-auto m-2' src={turtle.image} alt="" />
                                        </Link>
                                        <div className='flex flex-col'>
                                            <div className='nftinfocardtext'>
                                                <h2 value={turtle.tokenId} className='turtletokenid text-center text-base sm:text-lg md:text-xl'>#{turtle.tokenId}</h2>
                                                <h2 className='overflow-hidden turtlename text-center leading-[1.4rem] text-[1em]'>{turtle.name}</h2>
                                            </div>
                                            <div className='flex flex-col mt-1 mb-1'>
                                                <div>
                                                    <button onClick={() => approveTurtle(`${turtle.tokenId}`)} className='approvebutton mx-auto w-1/2 mt-1 flex justify-center text-center rounded-md h-[25px] bg-blue-300 hover:bg-[rgb(163,206,255)] border-b-[3px] border-blue-400 active:border-b-[1px] active:translate-y-[2px]'>
                                                        Approve
                                                    </button>
                                                </div>
                                                <div className='flex flex-row'>
                                                    <button onClick={() => stakeTurtle(`${turtle.tokenId}`)} className='stakebutton w-1/2 m-2 flex justify-center text-center rounded-md h-[25px] bg-green-300 hover:bg-[rgb(165,243,190)] border-b-[3px] border-green-400 active:border-b-[1px] active:translate-y-[2px]'>
                                                        <p className='staketext'>
                                                            Stake
                                                        </p>
                                                        <img className='stakeimage ml-[3px] my-auto w-4 h-4' src={shelllogo} alt="" />
                                                    </button>
                                                    <button onClick={() => unstakeTurtle(`${turtle.tokenId}`)} className='unstakebutton w-1/2 m-2 flex justify-center text-center rounded-md h-[25px] bg-red-300 hover:bg-[rgb(253,180,180)] border-b-[3px] border-red-400 active:border-b-[1px] active:translate-y-[2px]'>
                                                        Unstake
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )))}
                            </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            <footer className='mb-[20px] flex justify-center relative sm:bottom-1 md:bottom-3 lg:bottom-5 z-50 w-full h-[100px]'>
                <div className='mb-[20px] flex flex-row w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] h-full place-content-around'>
                    <a href='https://twitter.com/Arantuil' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(50,168,241,0.9)] transition-all border-[rgba(41,136,196,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                        <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={twitterlogo} alt="" />
                        <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Twitter</h1>
                    </a>

                    <a href='https://energywebnfts.com/markets' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(54,151,44,0.9)] transition-all border-[rgba(44,124,37,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                        <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={marketicon} alt="" />
                        <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Markets</h1>
                    </a>

                    <a href='https://t.me/susu_market' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(50,168,241,0.9)] transition-all border-[rgba(41,136,196,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                        <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={telegramlogo} alt="" />
                        <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Telegram</h1>
                    </a>
                </div>
            </footer>
        </div>
    );
}

export default MyTurtles;

// <a className='relative text-xl md:text-2xl w-[65px] md:w-[80px] flex text-center px-3 py-1 mx-auto justify-center rounded-2xl items-center
// bg-green-500 border-b-[5px] border-[rgb(24,136,65)] hover:brightness-[1.05] active:border-b-[3px] active:translate-y-[3px] active:mt-[2px]' id='playbutton' href="https://play.tubbyturtles.com/">Play</a>