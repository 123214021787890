import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyANuJiG-HTX_l5YZ9CePtZI1OdtebvVkhQ",
    authDomain: "tubbyturtles-86bc9.firebaseapp.com",
    databaseURL: "https://tubbyturtles-86bc9-default-rtdb.firebaseio.com",
    projectId: "tubbyturtles-86bc9",
    storageBucket: "tubbyturtles-86bc9dev",
    messagingSenderId: "817724247427",
    appId: "1:817724247427:web:7a384e44e15342c6307ab6",
    measurementId: "G-8DX8JQ62D2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app)