import { Link } from 'react-router-dom'
import house from '../assets/images/houseemoji.png'
import turtle from '../assets/images/turtleemoji.png'
import eyes from '../assets/images/eyesemoji.png'
import stats from '../assets/images/statsemoji.png'

const Header = () => {
    return (
        <header className='flex justify-center absolute top-0 sm:top-1 md:top-3 lg:top-5 z-50 w-full h-[100px]'>
            <div className='flex flex-row w-[90%] h-full'>
                <Link className='pt-1 md:py-2 my-auto w-1/4 bg-[rgba(50,168,241,0.9)] transition-all border-[rgba(41,136,196,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2' to='/'>
                    <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px]' src={house} alt="" />
                    <h1 className='font-bold flex justify-center text-lg sm:text-xl md:text-2xl lg:text-3xl'>Home</h1>
                </Link>

                <Link className='pt-1 md:py-2 my-auto w-1/4 bg-[rgba(54,151,44,0.9)] transition-all border-[rgba(44,124,37,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2' to='/mint'>
                    <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px]' src={turtle} alt="" />
                    <h1 className='font-bold flex justify-center text-lg sm:text-xl md:text-2xl lg:text-3xl'>Mint</h1>
                </Link>

                <Link className='pt-1 md:py-2 my-auto w-1/4 bg-[rgba(255,220,43,0.9)] transition-all border-[rgba(201,173,34,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2' to='/sea/all'>
                    <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px]' src={eyes} alt="" />
                    <h1 className='font-bold flex justify-center text-lg sm:text-xl md:text-2xl lg:text-3xl'>The sea</h1>
                </Link>

                <Link className='pt-1 md:py-2 my-auto w-1/4 bg-[rgba(255,43,43,0.9)] transition-all border-[rgba(207,36,36,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2' to='/stats'>
                    <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px]' src={stats} alt="" />
                    <h1 className='font-bold flex justify-center text-lg sm:text-xl md:text-2xl lg:text-3xl'>Stats</h1>
                </Link>
            </div>
        </header>
    );
}

export default Header;