import Stars from '../assets/images/moon.png'
import './stats.css'

import Background from '../components/charts/Background'
import Element from '../components/charts/Element'
import Shell from '../components/charts/Shell'
import Shellbrim from '../components/charts/Shellbrim'
import Eyes from '../components/charts/Eyes'
import Hat from '../components/charts/Hat'
import Mouth from '../components/charts/Mouth'
import Artifact from '../components/charts/Artifact'

const Statistics2 = () => {
    return (
        <div className='statspage sm:mb-2 md:mb-8 lg:mb-14 w-full h-full flex justify-center'>
            <img className='fixed top-0 left-0 w-full h-full object-cover z-[-1]' src={Stars} alt="" />
            <div className='text-xl h-full w-[90vw] sm:w-[80vw] md:w-[65vw] lg:w-[50vw] p-5 mt-24 sm:mt-28 md:mt-32 lg:mt-40 bg-white rounded-3xl'>
                <div className='w-full'>
                    <h1 className='font-bold'>Projected stats</h1>
                    <p className='text-lg'>The projected stats for each trait will appear here soon...</p>
                </div>
            </div>
        </div>
    );
}

export default Statistics2;